import React from 'react';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#848014', padding: '20px', textAlign: 'center', marginTop: "170px", fontFamily: "MyCustomFont, sans-serif", fontSize: "17px" }}>
      <p>&copy; EAD 2023</p>
    </footer>
  );
};

export default Footer;